exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aanvraag-tsx": () => import("./../../../src/pages/aanvraag.tsx" /* webpackChunkName: "component---src-pages-aanvraag-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cruise-tsx": () => import("./../../../src/pages/cruise.tsx" /* webpackChunkName: "component---src-pages-cruise-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-island-hopping-tsx": () => import("./../../../src/pages/island-hopping.tsx" /* webpackChunkName: "component---src-pages-island-hopping-tsx" */),
  "component---src-pages-islands-tsx": () => import("./../../../src/pages/islands.tsx" /* webpackChunkName: "component---src-pages-islands-tsx" */),
  "component---src-pages-tour-tsx": () => import("./../../../src/pages/tour.tsx" /* webpackChunkName: "component---src-pages-tour-tsx" */)
}

